import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  from,
  map,
  Observable,
  of,
  pipe,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Auth, authState, GoogleAuthProvider, idToken, signInWithPopup } from '@angular/fire/auth';
import { localStorageUID } from '../const';
import { UserModel } from '../models/user.model';

import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginModalComponent } from '../modals/login-modal/login-modal.component';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private auth: Auth = inject(Auth);

  authState$ = authState(this.auth);
  idToken$ = idToken(this.auth);
  currentUser$: Observable<any>;

  loadingUser$ = new BehaviorSubject<boolean>(false);
  user$$ = new BehaviorSubject<UserModel | null>(null);
  userPlan$ = new BehaviorSubject<boolean | null>(null);
  userPlan$$: Observable<boolean> = this.userPlan$.asObservable();
  private uid = '';
  private token = '';
  public userPlan = '';
  public redirectBack = '';
  public selectedProduct = '';

  constructor(
    private https: HttpClient,
    private router: Router,
    private dialogService: DialogService,
  ) {
    this.currentUser$ = combineLatest([
      this.authState$.pipe(filter(Boolean)),
      this.idToken$.pipe(filter(Boolean)),
    ]).pipe(
      switchMap(([user, token]) => {
        this.loadingUser$.next(true);
        this.token = token;
        if (!user) {
          return of(null);
        }
        if (this.uid !== user.uid) {
          this.userPlan = '';
          this.uid = user.uid;
          return this.getUserByUID(user.uid, token).pipe(
            map((user: any) => {
              if (user) {
                this.user$$.next(UserModel.set({ ...user }, []));
                return UserModel.set({ ...user }, []);
              }
            }),
          );
        } else {
          return of(null);
        }
      }),
      pipe(filter(Boolean)),
      switchMap((user) => this.verifySubscriptionPlan(user.uid)),
      take(1),
    );
  }

  login() {
    const provider = new GoogleAuthProvider();
    return this.oAuthLogin(provider).pipe(
      tap(() => {
        this.router.navigate(['/dashboard']);
      }),
    );
  }

  logOut() {
    localStorage.removeItem(localStorageUID);
    this.uid = '';
    return from(this.auth.signOut());
  }

  getUserPlanStatus() {
    return this.userPlan$$;
  }

  private oAuthLogin(provider: any) {
    return from(signInWithPopup(this.auth, provider)).pipe(
      tap((credential) => {
        localStorage.setItem(localStorageUID, credential.user?.uid);
      }),
    );
  }

  getUserByUID(uid: string, idToken: string): Observable<any> {
    const params = new HttpParams().set('uid', uid);
    const headers = new HttpHeaders({
      Authorization: idToken,
    });
    return this.getUserByUIDRequest(params, headers);
  }

  verifySubscriptionPlan(userId: string): Observable<any> {
    const params = new HttpParams().set('userId', userId);
    const headers = new HttpHeaders({
      Authorization: this.token,
    });
    return this.https.get(`/api/subscription/verify`, { params, headers }).pipe(
      tap((response: { plan: string }) => {
        this.userPlan = response.plan;
        this.userPlan$.next(response.plan === 'PAID');
        this.loadingUser$.next(false);
      }),
      catchError(() => {
        this.userPlan$.next(false);
        return of({ plan: 'FREE' });
      }),
    );
  }

  getCurrentUser() {
    const params = new HttpParams().set('uid', this.uid);
    const headers = new HttpHeaders({
      Authorization: this.token,
    });
    return this.getUserByUIDRequest(params, headers);
  }

  getUserByUIDRequest(params: HttpParams, headers: HttpHeaders) {
    return this.https.get(`/api/user`, { params, headers }).pipe(
      retryWhen((errors) => errors.pipe(delay(1000), take(5))),
      map((user: any) => user.userData),
      tap((user) => {
        this.user$$.next(UserModel.set({ ...user }, []));
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  showLoginModal() {
    this.dialogService.open(LoginModalComponent, {
      width: '100vw',
      height: '100vh',
      styleClass: 'pDialogFullScreen',
      baseZIndex: 9999999,
    });
  }
}
